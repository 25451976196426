import styles from './footer.module.scss'

import { Link } from 'react-scroll'

import UpArrow from '../../assets/arrow-up.png'
import Discord from '../../assets/discord.png'
import Github from '../../assets/github.png'
import Linkedin from '../../assets/linkedin.png'
import Twitter from '../../assets/twitter.png'

const Footer = () => (
  <div className={styles.footer}>
    <Link to="top" smooth={true}>
      <img src={UpArrow} alt="up-arrow" className={styles.footer__upArrow} />
    </Link>
    <div className={styles.footer__icons}>
      <a
        href="https://twitter.com/canberkelmal"
        className={styles.icon}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="twitter">
          <img src={Twitter} alt="twitter" className={styles.footer__github} />
        </i>
      </a>
      <a
        href="
        https://www.linkedin.com/in/canberk-elmal-078412189"
        className={styles.icon}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="linkedin">
          <img
            src={Linkedin}
            alt="linkedin"
            className={styles.footer__linkedin}
          />
        </i>
      </a>
      <a
        href="https://github.com/canberkelmal"
        className={styles.icon}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="github">
          <img src={Github} alt="github" className={styles.footer__discord} />
        </i>
      </a>
    </div>
  </div>
)

export default Footer
