import styles from './project-item.module.scss'
import Github from '../../assets/github-2.png'
import Demo from '../../assets/demo.png'
import Play from '../../assets/play.png'

const ProjectItem = ({
  name,
  description,
  demo,
  technologies,
  platforms,
  image,
  check,
}) => {
  const handleRedirect = () => {
    window.open(demo, '_blank')
  }

  return (
    <div className={styles.project} onClick={handleRedirect}>
      <div className={styles.project__image}>
        <img src={image} alt="random" />
      </div>
      <div className={styles.project__title}>
        <h1 className={styles.header}>{name}</h1>
      </div>
      <div className={styles.project__subtitle}>
        <p className={styles.subtitle}>{description}</p>
      </div>
      <div className={styles.project__info}>
        <div className={styles.project__technologies}>
          <h3 className={styles.header}>Technologies</h3>
          <p className={styles.subtitle}>{technologies.join(', ')}</p>
        </div>
        <div className={styles.project__technologies}>
          <h3 className={styles.header}>Platforms</h3>
          <p className={styles.subtitle}>{platforms?.join(', ')}</p>
        </div>
        <div className={styles.project__links}>
          <h3 className={styles.header}>Play</h3>
          <div className={styles.links}>
            {check ? (
              <a
                href={demo}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demo}
              >
                <i className={styles.demo__icon}>
                  <img src={Demo} alt="demo" />
                </i>
              </a>
            ) : (
              <a
                href={demo}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.demo}
              >
                <i className={styles.demo__icon}>
                  <img src={Play} alt="demo" />
                </i>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectItem
