import styles from './about.module.scss'

import CanberkAsset from '../../assets/canberkelmal.png'

const About = () => (
  <div className={styles.about}>
    <div className={styles.about__image}>
      <img src={CanberkAsset} alt="random" />
    </div>
    <div className={styles.about__title}>
      <h1 className={styles.header}>Hi,</h1>
      <h3 className={styles.header}>I'm Canberk Elmal</h3>
      <p className={styles.subtitle}>
        <br />I started my{' '}
        <span className={styles.highlight}>Game Development</span> career in
        July 2022 as an engineer who had already adopted all the principles of
        engineering.
      </p>
      <a
        href="resume.pdf"
        target="_blank"
        rel="noreferrer"
        className={styles.resume}
      >
        View Resume
      </a>
    </div>
  </div>
)

export default About
