import Carnival from './assets/carnival.png'
import Galaxy from './assets/galaxy.png'
import Racfathers from './assets/racfathers.png'
import Rocket from './assets/rocket.png'
import Vacuum from './assets/vacuum2.png'

export const PROJECTS_DATA = [
  {
    id: 1,
    name: 'Racfathers',
    description: `Racfathers is a side-scrolling platform game where players control unique NFT characters, each with distinct boost combinations. The game features over 1,700 NFTs, allowing players to engage in combat against various enemies and bosses, earning points to climb the leaderboard. Depending on their rank in the project's Discord server, senior players can equip weapons for additional advantages. Players can accumulate points by defeating enemies and bosses, securing a position on the scoreboard. Weekly and monthly rewards are distributed based on leaderboard rankings.`,
    demo: 'https://racfathers.com/test/demoWGL/index.html',
    image: Racfathers,
    technologies: [
      'Unity',
      'C#',
      'WebGL',
      'DoTween',
      'Thirdweb API',
      'Database Communication',
      'URL-based Image Retrieval',
    ],
    platforms: ['WebGL (Official Website)', 'Mobile', 'Telegram Mini-App'],
    check: true,
  },
  {
    id: 2,
    name: 'Carnival Shooter',
    description: `Carnival Shooter is a WebGL game where players use a water gun to inflate and pop toy balloons. As players progress, they earn money, upgrade their equipment, and merge toys to unlock higher-level rewards.`,
    demo: 'https://www.crazygames.com/game/carnival-shooter',
    image: Carnival,
    technologies: ['Unity', 'C#', 'WebGL', 'DoTween'],
    platforms: ['WebGL (PC & Mobile)'],
  },
  {
    id: 3,
    name: 'Galaxy Clicker',
    description: `Galaxy Clicker is an idle game where players earn income as planets revolve around the sun. The game features a deep progression system with various planets, stars, and star systems to explore. Players can buy and merge planets, upgrade different stats such as stamina, income, and speed, and optimize their resource management to maximize earnings.`,
    demo: 'https://www.crazygames.com/game/galaxy-clicker',
    image: Galaxy,
    technologies: ['Unity', 'C#', 'WebGL', 'DoTween'],
    platforms: ['WebGL (PC & Mobile)'],
  },
  {
    id: 4,
    name: 'Rocket Launch',
    description: `Rocket Launch is a physics-based game where players control the trajectory and power of a rocket to reach various checkpoints. The challenge lies in managing fuel efficiency and trajectory accuracy to navigate through different levels successfully. The game features an engaging progression system, allowing players to upgrade their rocket’s capabilities over time.`,
    demo: 'https://www.crazygames.com/game/rocket-launch',
    image: Rocket,
    technologies: ['Unity', 'C#', 'WebGL', 'DoTween'],
    platforms: ['WebGL (PC & Mobile)'],
  },
  {
    id: 5,
    name: 'Vacuum Juice',
    description: `Vacuum Juice is a hybrid casual mobile game that combines a runner mechanic with an idle gameplay loop. Players use a vacuum to collect fruits during the runner phase, and the collected juice is sold in the idle section to generate income. The earnings from juice sales are used to upgrade and expand the idle section.`,
    demo: 'https://play.google.com/store/apps/details?id=com.Game4U.VacuumJuice&hl=tr',
    image: Vacuum,
    technologies: ['Unity', 'C#', 'WebGL', 'DoTween', 'Mobile Optimization'],
    platforms: ['Mobile (Android)'],
  },
]
