import React, { Fragment } from 'react'
import Navbar from './components/navbar/navbar.component'
import About from './components/about/about.component'
import Projects from './components/projects/projects.component'
import Contact from './components/contact/contact.component'
import Footer from './components/footer/footer.component'

import { PROJECTS_DATA } from './data'

import './App.css'

function App() {
  return (
    <Fragment>
      <div id="top" />
      <Navbar />
      <div className="App">
        <div id="about" />
        <About />
      </div>
      <div id="projects" style={{ marginBottom: 90 }} />
      <Projects data={PROJECTS_DATA} />
      <div className="App">
        <div id="contact" />
        <Contact />
      </div>
      <Footer />
    </Fragment>
  )
}

export default App
